import { Link as RouterLink } from 'react-router-dom';
import GoogleButton from 'react-google-button';
// material
import { styled } from '@mui/material/styles';
import { Box, Divider, Card, Stack, Link, Alert, Tooltip, Container, Typography, Button } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';

// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import LoginForm from '../../components/authentication/login/LoginForm';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function LoginSignup() {
  const { method, login } = useAuth();

  const handleLoginAuth0 = async () => {
    try {
      await login();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RootStyle title="Login | Shoshin School">
      {/* <AuthLayout>
        Don’t have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to={'/signup'}>
          Sign up
        </Link>
      </AuthLayout> */}

      <MHidden width="mdDown">
        <SectionStyle style={{ background: 'linear-gradient(229.57deg, #9796f0 -11.47%, #fbc7d4 76.84%)' }}>
          <Typography variant="h5" sx={{ px: 5, mt: 10, mb: 5 }}>
            Welcome to Shoshin School!
          </Typography>
          <img src="/static/illustrations/illustration_login_signup.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Login / Sign Up to Shoshin School
            </Typography>
          </Stack>

          {/* {method !== 'auth0' ? (
            <LoginForm />
          ) : (
            <Button fullWidth size="large" type="submit" variant="contained" onClick={handleLoginAuth0}>
              Login
            </Button>
          )} */}

          {/* <Divider sx={{ my: 3 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              or
            </Typography>
          </Divider> */}

          <Stack direction="row" spacing={2}>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <GoogleButton
                type="dark"
                onClick={(e) => {
                  e.preventDefault();
                  const queryParameters = new URLSearchParams(window.location.search);
                  const source = queryParameters.get('source');
                  let reqParamsUrl = `source=${source}`;
                  if (reqParamsUrl) {
                    reqParamsUrl = '/auth/google?' + reqParamsUrl;
                    window.location.href = reqParamsUrl;
                  } else {
                    window.location.href = '/auth/google';
                  }
                }}
              />
            </div>
          </Stack>

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to={'/signup'}>
                Sign Up
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
